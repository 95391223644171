import React, { useEffect, useState } from "react";
/* NK Podsused */
import NKPodsused from "../../../Assets/Projects/NK-Podsused.jpg";
import NKPodsused1 from "../../../Assets/Projects/NK-Podsused1.jpg";
import NKPodsused2 from "../../../Assets/Projects/NK-Podsused2.jpg";
/* ChatApp */
import Wazapp from "../../../Assets/Projects/Wazapp.jpg";
import Wazapp1 from "../../../Assets/Projects/Wazapp1.jpg";
import Wazapp2 from "../../../Assets/Projects/Wazapp2.jpg";

/* Autoconnect */
import Autoconnect from "../../../Assets/Projects/Autoconnect.jpg";
import Autoconnect1 from "../../../Assets/Projects/Autoconnect1.jpg";
import Autoconnect2 from "../../../Assets/Projects/Autoconnect2.jpg";
/* Bite & Slice */
import BiteSlice from "../../../Assets/Projects/Bite-Slice.jpg";
import BiteSlice1 from "../../../Assets/Projects/Bite-Slice1.jpg";
import BiteSlice2 from "../../../Assets/Projects/Bite-Slice2.jpg";

/* Weather App */
import WeatherApp from "../../../Assets/Projects/WeatherApp.jpg";
import WeatherApp1 from "../../../Assets/Projects/WeatherApp1.jpg";
import WeatherApp2 from "../../../Assets/Projects/WeatherApp2.jpg";
/* Don Bosco */
import DonBosco from "../../../Assets/Projects/DonBosco.jpg";
import DonBosco1 from "../../../Assets/Projects/DonBosco1.jpg";
import DonBosco2 from "../../../Assets/Projects/DonBosco2.jpg";
import { useLocation } from "react-router-dom";

const projects = [
  {
    id: 1,
    title: "NK Podsused",
    technologies: [
      "HTML",
      "CSS",
      "Sass",
      "JavaScript",
      "React",
      "Firebase",
      "Cloudflare",
    ],
    description1:
      "Welcome to the website of the Podsused Football Club! This platform serves as a central place for everything related to our football club, providing a comprehensive overview of our team's activities, history, news, and much more.",
    description2:
      "This website is created for easy navigation, allowing users to choose their preferred theme, whether darker or lighter.",
    liveLink: "https://nkpodsused.com",
    codeLink: "https://github.com/TeoIvanus/NK-Podsused",
    images: [NKPodsused, NKPodsused1, NKPodsused2],
  },
  {
    id: 2,
    title: "Wazaap",
    technologies: ["HTML", "CSS", "Sass", "React", "Scaledrone", "Cloudflare"],
    description1:
      "Wazapp or originaly chatapp is my final project for the Algebra University Front-End Developer course. In this project I built a responsive chat application connected to Scaledrone, featuring custom elements like randomly generated names and emojis.",
    description2:
      "Users can enjoy a personalized experience with a Dark/Light mode toggle. The application is deployed on Cloudflare for optimized performance and security.",
    liveLink: "https://chatapp-teoivanus.pages.dev/",
    codeLink: "https://github.com/TeoIvanus/ChatApp-TeoIvanus",
    images: [Wazapp, Wazapp1, Wazapp2],
  },
  {
    id: 3,
    title: "Autoconnect",
    technologies: ["HTML", "CSS", "JavaScript", "React", "Web3Forms"],
    description1:
      "AutoConnect is a platform designed to connect auto repair services with clients, offering a seamless experience for both service providers and customers.",
    description2:
      "The website is designed for easy navigation, allowing users to quickly find the services they need with a modern, user-friendly interface.",
    liveLink: "https://autoconnect.hr/",
    codeLink: "https://github.com/TeoIvanus/Autoconnect",
    images: [Autoconnect, Autoconnect1, Autoconnect2],
  },
  {
    id: 4,
    title: "Bite & Slice",
    technologies: ["HTML", "CSS", "JavaScript", "React"],
    description1:
      "Bite & Slice Restaurant's website offers an intuitive and user-friendly experience where you can easily explore the best offerings of the restaurant. From the location options to the diverse menu, everything is easily accessible. There is a convenient side button for quick table reservations, making the dining experience seamless from start to finish.",
    description2:
      "The design features a modern aesthetic, using a sleek dark background with vibrant yellow and orange accents that provide an eye-catching contrast. This combination of colors creates a stylish and appealing look, inviting users to explore the menu and make reservations in a visually pleasing environment.",
    liveLink: "https://biteslice.pages.dev/",
    codeLink: "https://github.com/TeoIvanus/BiteSlice",
    images: [BiteSlice, BiteSlice1, BiteSlice2],
  },
  {
    id: 5,
    title: "Weather App",
    technologies: ["HTML", "CSS", "JavaScript", "React"],
    description1:
      "Weather app, a place where you can explore forecasts from cities all around the world! Quickly find out the current temperature, humidity, and upcoming weather for the next few days.",
    description2:
      "This Weather app is fully responsive. Whether you use it on a computer or a mobile device, have a smooth and easy experience while navigating through the app.",
    liveLink: "https://weather-teo.pages.dev/",
    codeLink: "https://github.com/TeoIvanus/Weather-App",
    images: [WeatherApp, WeatherApp1, WeatherApp2],
  },
  {
    id: 6,
    title: "Don Bosco Church",
    technologies: ["HTML", "CSS", "JavaScript", "React"],
    description1:
      "Don Bosco - Podsused, newly designed church website with a green theme and a modern design.",
    description2:
      "Designed for easy navigation and user-friendly experience. With all the important information at the top, ensuring to find what you need.",
    liveLink: "https://donbosco-podsused.pages.dev/",
    codeLink: "https://github.com/TeoIvanus/DonBosco-Podsused",
    images: [DonBosco, DonBosco1, DonBosco2],
  },
];

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const targetId = hash.replace("#", "");
      const element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [hash]);

  const openModal = (project, index) => {
    setSelectedProject(project);
    setCurrentImageIndex(index);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setCurrentImageIndex(0);
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % selectedProject.images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prev) =>
        (prev - 1 + selectedProject.images.length) %
        selectedProject.images.length
    );
  };

  return (
    <div className="section projects-container">
      {projects.map((project) => (
        <div key={project.id} id={project.name} className="project-card">
          {/* Glavna slika */}
          <div className="projects-images">
            <img
              src={project.images[0]}
              alt={project.title}
              onClick={() => openModal(project, 0)}
              className="main-image"
            />
            <div className="small-images">
              {/* Manje slike */}
              <img
                src={project.images[1]}
                alt={project.title}
                onClick={() => openModal(project, 1)}
              />
              <img
                src={project.images[2]}
                alt={project.title}
                onClick={() => openModal(project, 2)}
              />
            </div>
          </div>
          <h3>{project.title}</h3>
          <div className="skills">
            {project.technologies.map((tech, idx) => (
              <span key={idx}>{tech}</span>
            ))}
          </div>
          <p>{project.description1}</p>
          <p>{project.description2}</p>

          <div className="project-links">
            <a
              href={project.liveLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Live
            </a>
            <a
              href={project.codeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Code
            </a>
          </div>
        </div>
      ))}

      {selectedProject && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              <i class="fi fi-rs-cross-small"></i>
            </button>
            <button className="prev-button" onClick={prevImage}>
              <i class="fi fi-rs-angle-left"></i>
            </button>
            <img
              src={selectedProject.images[currentImageIndex]}
              alt={selectedProject.title}
              className="modal-image"
            />
            <button className="next-button" onClick={nextImage}>
              <i class="fi fi-rs-angle-right"></i>
            </button>
          </div>
        </div>
      )}

      <div className="info">
        <h2>Love my work?</h2>
        <p>
          If you enjoy my work or have a project in mind, I'd love to hear from
          you! Feel free to reach out and share your thoughts.
        </p>
        <button
          className="mail-btn"
          onClick={() =>
            (window.location.href = "mailto:help.teoivanus@gmail.com")
          }
        >
          Mail Me
        </button>
      </div>
    </div>
  );
};

export default Projects;
